module.exports = [{
      plugin: require('/Users/pn/Projects/neuroth-messe-gatsby-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-138064291-1","anonymize":true,"respectDNT":true,"cookieExpires":0,"storeGac":false},
    },{
      plugin: require('/Users/pn/Projects/neuroth-messe-gatsby-website/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#f49303","showSpinner":true},
    },{
      plugin: require('/Users/pn/Projects/neuroth-messe-gatsby-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
