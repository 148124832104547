// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-js": () => import("/Users/pn/Projects/neuroth-messe-gatsby-website/src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-pages-404-js": () => import("/Users/pn/Projects/neuroth-messe-gatsby-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("/Users/pn/Projects/neuroth-messe-gatsby-website/src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-erfolgsprojekte-js": () => import("/Users/pn/Projects/neuroth-messe-gatsby-website/src/pages/erfolgsprojekte.js" /* webpackChunkName: "component---src-pages-erfolgsprojekte-js" */),
  "component---src-pages-impressum-js": () => import("/Users/pn/Projects/neuroth-messe-gatsby-website/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/Users/pn/Projects/neuroth-messe-gatsby-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ueber-uns-js": () => import("/Users/pn/Projects/neuroth-messe-gatsby-website/src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */)
}

