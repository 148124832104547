import { createStore as reduxCreateStore } from 'redux'

const reducer = (state, action) => {
  if (action.type === `TOGGLE_MENU`) {
    return Object.assign({}, state, {
      menuVisibility: !state.menuVisibility,
    })
  }
  return state
}

const initialState = { menuVisibility: false }

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore
